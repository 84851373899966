import { useEffect } from 'react'
import axios from 'axios'
import { Coords } from '@/types/Coords'
import { SearchQueryCapture } from '@/util/PosthogCaptures'
import * as Sentry from '@sentry/browser'
import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

const useSearch = (
  query: string = '',
  filters: string[] = [],
  cuisines: string[],
  coords: Coords,
  distance: string,
  state: boolean = true,
  initialZoom: number,
  searchType: string,
  selectedCard: number
) => {
  const router = useRouter()
  const { user } = useAuth0()

  const joinedCuisines = cuisines.join(',')
  const joinedFilters = filters.join(',')
  const location = `${coords.latitude},${coords.longitude}`

  const queryKeys = {
    query: query,
    cuisines: joinedCuisines,
    filters: joinedFilters,
    distance: distance,
    location: location,
    searchType: searchType,
    userId: user?.sub,
  }

  const useSearchQuery = async () => {
    let data = null
    try {
      data = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/search`, {
        params: {
          query,
          cuisines: joinedCuisines,
          filters: joinedFilters,
          distance: distance,
          searchType: searchType,
          location: location,
          userID: user?.sub,
        },
      })
      return data
    } catch (e) {
      throw e
    } finally {
      updateQueryParams({
        q: query,
        d: distance,
        f: joinedFilters,
        c: location,
        st: searchType,
        z: initialZoom,
        sc: selectedCard,
      })
    }
  }

  const {
    data,
    error,
    isPending: loadingResults,
    status,
  } = useQuery({
    queryKey: ['useSearch', queryKeys],
    queryFn: useSearchQuery,
    enabled: state,
    select: (d) => (d ? d.data : []),
  })

  if (error) {
    if (error.name === 'AbortError') {
      SearchQueryCapture(
        'search:query_canceled',
        query,
        filters,
        cuisines,
        coords,
        []
      )
    } else {
      Sentry.captureException(`failed to fetch search: ${error}`)
      SearchQueryCapture(
        'search:query_failed',
        query,
        filters,
        cuisines,
        coords,
        []
      )
    }
  }

  if (status === 'success') {
    SearchQueryCapture(
      'search:query_success',
      query,
      filters,
      cuisines,
      coords,
      data.results
    )
  }

  const updateQueryParams = (newParams: Record<string, string | number>) => {
    if (router.isReady) {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          ...newParams,
        },
      })
    }
  }

  useEffect(() => {
    updateQueryParams({
      sc: selectedCard,
    })
  }, [selectedCard])

  return { loadingResults, data, error }
}

export default useSearch
