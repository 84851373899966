import axios from 'axios'
import { useEffect, useState, useRef, useMemo } from 'react'
import { checkPlatform } from '@/util/DeviceCheck'
import HourBlock from '@/components/HourBlock'
import Accordion from '@/components/Accordion'
import {
  Square2StackIcon,
  ExclamationTriangleIcon,
  ArrowLeftIcon,
  PhotoIcon,
} from '@heroicons/react/24/solid'
import { Tab } from '@headlessui/react'
import { useAuth0 } from '@auth0/auth0-react'
import ReportPopup from '@/components/ReportPopup'
import Menu from '@/components/merchant/menu'
import MerchantImages from '@/components/merchant/images'
import { FoodMerchant, PriceRange } from '@/types/FoodMerchant'
import DetailActionButtons from '@/components/DetailActionButton'
import { MenuProvider } from '@/util/MenuContext'
import { useRouter } from 'next/router'
import { useFilterStore } from '@/hooks/useFilterStore'
import { useQuery } from '@tanstack/react-query'
import { getMerchantPage } from '@/util/queries'

type Props = {
  item: FoodMerchant | null
  selectedMapMarker: number
  setSelectedMapMarker: (props: number) => void
}

const MerchantDetailCard = (props: Props) => {
  const { item, selectedMapMarker, setSelectedMapMarker } = props
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [viewState, setViewState] = useState(false)
  const [liked, setLiked] = useState('')
  const [reportItem, setReportItem] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [selectedPriceRange, setSelectedPriceRange] =
    useState<PriceRange | null>(null)

  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const router = useRouter()

  const tabRef = useRef<HTMLElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)

  const {
    data: page,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['merchantId', item?.merchant_id],
    queryFn: getMerchantPage,
  })

  const items = useMemo(() => (page ? page.items : []), [page])
  const categories = useMemo(
    () => (page ? page.items.map((i) => i.name) : []),
    [page]
  )

  const merchantPriceRange = useMemo(() => {
    let highestCost = -Infinity
    let lowestCost = Infinity

    items.forEach((item) => {
      item.items.forEach((i) => {
        const upCost = Math.ceil(i.cost)
        const downCost = Math.floor(i.cost)
        if (upCost > highestCost) {
          highestCost = upCost
        }
        if (downCost < lowestCost) {
          lowestCost = downCost
        }
      })
    })

    return {
      min: lowestCost,
      max: highestCost,
    }
  }, [items])

  useEffect(() => {
    const getLikes = async () => {
      if (!page) return
      const slug = page.id
      const token = await getAccessTokenSilently()
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/merchant/${slug}/like`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setLiked(res.data.like)
    }

    if (isAuthenticated && page) {
      getLikes()
      console.log('liked')
    }
  }, [
    isAuthenticated,
    page,
    selectedPriceRange,
    selectedCategory,
    items,
    getAccessTokenSilently,
  ])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const platForm = checkPlatform()
      if (platForm === 'isApple' || platForm === 'isIOS') {
        setViewState(true)
      }
    }
  }, [])

  const errorImages = useRef(new Set<number>())

  const handleImageError = (id: number) => {
    if (errorImages.current.has(id)) return
    errorImages.current.add(id)
  }

  const filteredPhotos = page?.images
    ? page?.images.filter((p) => !errorImages.current.has(p.id))
    : []

  const [copied, setCopied] = useState(false)

  const handleMerchantPhotoClick = () => {
    if (sidebarRef.current && tabRef.current) {
      setSelectedIndex(2)
      sidebarRef.current.scrollTo({
        top: tabRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const copyToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = window.location.origin + router.asPath
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    page && (
      <>
        {reportItem && (
          <ReportPopup
            trigger={reportItem}
            setTrigger={setReportItem}
            itemId={page.id}
          />
        )}

        <div
          className="absolute top-2 left-2 bg-white text-black rounded-md p-1 z-20 cursor-pointer border-dark border-2 dark:border-0"
          onClick={() => setSelectedMapMarker(0)}
        >
          <ArrowLeftIcon className={`h-6 w-auto `} />
        </div>

        <div
          className={`relative h-detailcard overflow-auto scroll-auto space-y-2 no-scrollbar rounded-md bg-gray-200 dark:bg-dark
          ${selectedMapMarker !== 0 ? 'block opacity-100' : 'hidden opacity-0'}
          `}
          ref={sidebarRef}
        >
          <div className={`relative`}>
            <div className={'image-slider no-scrollbar w-full h-[300px]'}>
              {page?.images?.length > 0 ? (
                filteredPhotos.map((i) => (
                  <div className={`relative`} key={i.id}>
                    <img
                      onError={() => handleImageError(i.id)}
                      src={`${process.env.NEXT_PUBLIC_API_URL}/assets/images/${i.id}/${i.path_to_image}.jpg`}
                      className={`h-[300px] rounded-md object-cover max-w-none ${page.images.length < 2 ? 'w-full' : 'w-[300px]'}`}
                      alt={page?.name}
                    />

                    {page?.images?.length > 0 && (
                      <div
                        id="doesitexist"
                        className={
                          'absolute bottom-6 left-1 bg-opacity-70 bg-white rounded-md text-black p-1 text-sm'
                        }
                      >
                        {page.images[0]?.attribution}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <img
                  srcSet={
                    'https://ironchopsticks.hopto.org/assets/dodo-placeholder.jpg 500w, https://ironchopsticks.hopto.org/assets/dodo-placeholder-wide.jpg 1000w'
                  }
                  alt={page?.name}
                  className={`h-[300px] rounded-md object-cover max-w-none w-full`}
                />
              )}
            </div>

            {filteredPhotos.length > 1 && (
              <div
                className="absolute top-2 right-2 bg-white text-black rounded-md p-1 cursor-pointer"
                onClick={handleMerchantPhotoClick}
                data-test-id="photo-icon"
              >
                <PhotoIcon className="h-6 w-auto " />
              </div>
            )}
            <div
              className={`absolute bottom-4 w-full p-2 rounded-b-md bg-gradient-to-t from-black from-0% via-btot via-20% to-transparent to-90% `}
            >
              <div className={` mx-auto max-w-7xl `}>
                <p
                  className={`text-2xl font-bold text-white`}
                  data-test-id="merchant-view-name"
                >
                  {page.name}
                </p>
                <HourBlock hours={page.hours} />
                <p className={`text-white`}>{page.cuisine}</p>
              </div>
            </div>
          </div>

          <div className="bg-white dark:bg-dark mx-auto max-w-7xl mt-4">
            <DetailActionButtons
              recordId={page.id}
              recordType={`merchant`}
              liked={liked}
              onReportClick={() => setReportItem(true)}
              locationUrl={
                viewState
                  ? `maps:?q=${page.coordinates.latitude},${page.coordinates.longitude}`
                  : `https://maps.google.com?q=${page.name} ${page.location}`
              }
            />
            <hr className={`block my-4 border-black dark:border-[#9797AA]`} />
            <div className={`block px-4 my-4`}>
              <div className={`block`}>
                <a
                  href={
                    viewState
                      ? `maps:?q=${page.coordinates.latitude},${page.coordinates.longitude}`
                      : `https://maps.google.com?q=${page.name} ${page.location}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`inline-block w-full text-center rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm`}
                >
                  Navigation
                </a>
                <p className={`text-xs font-bold my-4`}>Details</p>
                {page.phone}
                <div className={`flex flex-row justify-between items-center`}>
                  <p className={`mb-4 w-1/2`}>{page.location}</p>
                  <Square2StackIcon
                    onClick={() => copyToClipboard()}
                    className={`w-6 h-6 cursor-pointer ${copied && `text-blue-500`}`}
                  />
                </div>
              </div>

              <Tab.Group
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
              >
                <Tab.List className={`w-full gap-2  flex`} ref={tabRef}>
                  <Tab
                    className={`w-1/3 rounded-md p-2 focus:outline-none
                  ui-selected:bg-light ui-selected:text-black ui-selected:border ui-selected:border-lightdark
                  ui-not-selected:bg-lightdark ui-not-selected:text-white`}
                  >
                    Overview
                  </Tab>
                  <Tab
                    className={`w-1/3 rounded-md p-2 focus:outline-none
                  ui-selected:bg-light ui-selected:text-black ui-selected:border ui-selected:border-lightdark
                  ui-not-selected:bg-lightdark ui-not-selected:text-white`}
                  >
                    Menu
                  </Tab>
                  {/*<Tab*/}
                  {/*  className={`w-1/4 rounded-md p-2 focus:outline-none*/}
                  {/*ui-selected:bg-light ui-selected:text-black ui-selected:border ui-selected:border-lightdark*/}
                  {/*ui-not-selected:bg-lightdark ui-not-selected:text-white`}*/}
                  {/*>*/}
                  {/*  Reviews*/}
                  {/*</Tab>*/}
                  <Tab
                    className={`w-1/3 rounded-md p-2 focus:outline-none
                  ui-selected:bg-light ui-selected:text-black ui-selected:border ui-selected:border-lightdark
                  ui-not-selected:bg-lightdark ui-not-selected:text-white`}
                  >
                    Photos
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <hr
                      className={`col-span-2 block my-4 border-black dark:border-[#9797AA]`}
                    />
                    {page?.about && page.about.length > 0 && (
                      <Accordion
                        title={`About`}
                        isOpen={true}
                        className={`block`}
                      >
                        {page.about}
                      </Accordion>
                    )}
                    {page.amenities.length > 0 && (
                      <div className={`block`}>
                        <hr
                          className={`col-span-2 block my-4 border-black dark:border-[#9797AA]`}
                        />
                        <p>Amenities</p>
                        <ul className={`grid grid-cols-2 my-4`}>
                          {page.amenities &&
                            page.amenities.split(',').map((a) => (
                              <li key={a} className={`text-sm`}>
                                {a}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Tab.Panel>
                  <Tab.Panel>
                    <MenuProvider
                      initialState={{
                        category: 'all',
                        priceRange: {
                          min: merchantPriceRange.min,
                          max: merchantPriceRange.max,
                        },
                      }}
                    >
                      <Menu
                        isSidebar={true}
                        categories={categories}
                        items={items}
                        merchantPriceRange={merchantPriceRange}
                      />
                    </MenuProvider>
                  </Tab.Panel>
                  {/*<Tab.Panel>*/}
                  {/*  <Reviews />*/}
                  {/*</Tab.Panel>*/}
                  <Tab.Panel>
                    <MerchantImages photos={filteredPhotos} />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>

              <div
                className={`flex flex-row gap-2 my-8 cursor-pointer text-black dark:text-white bg-accent dark:bg-lightdark px-3.5 py-2.5 w-full rounded-md justify-center`}
                onClick={() => setReportItem(true)}
              >
                <ExclamationTriangleIcon
                  className={`h-6 w-auto cursor-pointer`}
                />
                Report
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default MerchantDetailCard
