import { FoodMerchant } from '@/types/FoodMerchant'
import { useFilterStore } from '@/hooks/useFilterStore'
import Accordion from '@/components/Accordion'
import PillFiltersContainer from '@/components/PillFiltersContainer'
import RestaurantsMenuToggle from '@/components/RestaurantsMenuToggle'
import Pill from './Pill'
import MapFilterSlider from '@/components/MapFilterSlider'
import { useMemo } from 'react'
import IndieChainToggle from '@/components/IndieChainToggle'

type Props = {
  searchResults: FoodMerchant[]
  maxPrice: number
  loadingResults: boolean
}

const MapFiltersBar = (props: Props) => {
  const { searchResults, maxPrice, loadingResults } = props

  const filters = useFilterStore((state) => state.filters)
  const searchType = useFilterStore((state) => state.searchType)
  const addFilter = useFilterStore((state) => state.addFilter)
  const removeFilter = useFilterStore((state) => state.removeFilter)
  const resetFilters = useFilterStore((state) => state.resetFilters)
  const incrementResetFilterCounter = useFilterStore(
    (state) => state.incrementFilterResetCounter
  )
  const isMobile = useFilterStore((state) => state.isMobile)

  const preparedSelectedFilters = useMemo(() => {
    return filters.filter((name) => name !== 'indie' && name !== 'restaurant')
  }, [filters])

  const handleReset = () => {
    resetFilters()
    incrementResetFilterCounter()
  }

  return (
    <div>
      {!isMobile && <RestaurantsMenuToggle />}
      <div
        className={` border-[#595959] border-b pb-2 mb-2 flex w-full ${preparedSelectedFilters.length > 0 ? 'justify-between' : 'justify-end'}`}
      >
        <p
          className={`font-bold ${preparedSelectedFilters.length > 0 ? '' : 'hidden'}`}
        >
          Selected Filters
        </p>
        {loadingResults ? (
          <p className="justify-self-end">Loading...</p>
        ) : (
          <p className="justify-self-end">{`${searchResults.length} results`}</p>
        )}
      </div>

      {preparedSelectedFilters.length > 0 && (
        <div className="flex flex-wrap mb-2">
          {preparedSelectedFilters.map((name) => (
            <Pill key={name} name={name} shouldSetSearch={true} />
          ))}
        </div>
      )}
      <div className="md:w-[325px] w-full">
        {searchType === 'meals' && (
          <div>
            <MapFilterSlider />
          </div>
        )}
      </div>
      <div>
        <Accordion title={'Most Popular'} isOpen={true} boldTitle={true}>
          <div className="border-t mb-3 border-[#595959]" />
          <PillFiltersContainer
            shouldSetSearch={true}
            customList={[
              'gluten-free',
              'vegan',
              'heart healthy',
              'dairy-free',
              'low glycemic index',
            ]}
          />
        </Accordion>
      </div>
      <div>
        <Accordion title={`Restaurant Type`} isOpen={true} boldTitle={true}>
          <div className="border-t mb-3 border-[#595959]" />
          <IndieChainToggle shouldSetSearch={true} />
        </Accordion>
      </div>
      <div>
        <Accordion title={`Restrictions`} isOpen={true} boldTitle={true}>
          <div className="border-t mb-3 border-[#595959]" />
          <PillFiltersContainer shouldSetSearch={true} />
        </Accordion>
      </div>

      <div>
        <button
          className=" font-bold text-center border-white w-full border-2 p-2 mt-3"
          onClick={handleReset}
        >
          Clear Filters
        </button>
      </div>
    </div>
  )
}

export default MapFiltersBar
