import { useEffect, useState, useRef, useMemo } from 'react'
import { FoodMerchant, MobileView } from '@/types/FoodMerchant'
import MapView from '@/components/MapView'
import CustomHead from '@/components/CustomHead'
import { Coords } from '@/types/Coords'
import useSearch from '@/hooks/useSearch'
import { useRouter } from 'next/router'
import 'leaflet/dist/leaflet.css'
import { useFilterStore } from '@/hooks/useFilterStore'
import MobileHeader from '@/components/MobileHeader'

export default function Home() {
  const [distance, setDistance] = useState('')
  const [onload, setOnload] = useState(true)
  const [zoomLevel, setZoomLevel] = useState(13)
  const [mobileView, setMobileView] = useState<MobileView>('map')

  const selectedFilters = useFilterStore((state) => state.filters)
  const searchState = useFilterStore((state) => state.searchState)
  const selectedCard = useFilterStore((state) => state.selectedCard)
  const selectedCuisines = useFilterStore((state) => state.selectedCuisines)
  const query = useFilterStore((state) => state.query)
  const searchType = useFilterStore((state) => state.searchType)
  const isMobile = useFilterStore((state) => state.isMobile)
  const coords = useFilterStore((state) => state.coords)

  const setSelectedFilters = useFilterStore((state) => state.setFilters)
  const setSelectedCard = useFilterStore((state) => state.setSelectedCard)
  const setQuery = useFilterStore((state) => state.setQuery)
  const setSearchInput = useFilterStore((state) => state.setSearchInput)
  const setSearchType = useFilterStore((state) => state.setSearchType)
  const setIsMobile = useFilterStore((state) => state.setIsMobile)

  const containerRef = useRef<HTMLDivElement>(null)

  const { data, loadingResults } = useSearch(
    query,
    selectedFilters,
    selectedCuisines,
    coords,
    distance,
    searchState,
    zoomLevel,
    searchType,
    selectedCard
  )

  const results: FoodMerchant[] | [] = useMemo(
    () => (data ? data.results : []),
    [data]
  )

  const activeFilters = useMemo(
    () => (data ? data.activePillFilters : []),
    [data]
  )

  const router = useRouter()

  useEffect(() => {
    const checkMobile = () => {
      if (!containerRef.current) {
        return
      }
      setIsMobile(containerRef.current.clientWidth)
    }
    window.addEventListener('resize', checkMobile)
    checkMobile()
    return () => window.removeEventListener('resize', checkMobile)
  }, [setIsMobile])

  const handleMobileView = (id: MobileView) => {
    if (mobileView === id) {
      setMobileView('map')
      return
    }
    setMobileView(id)
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && router.query) {
      if (router.isReady && onload) {
        const { sc, st, q, c, d, f, z } = router.query as {
          sc: string | undefined
          st: 'restaurants' | 'meals' | undefined
          q: string | undefined
          c: string | undefined
          d: string | undefined
          f: string | undefined
          z: string | undefined
        }
        //this only updates after things have loaded
        if (f) {
          const fArray = f.split(',')
          setSelectedFilters(fArray)
        }

        if (!f && selectedFilters && selectedFilters.length > 0) {
          setSelectedFilters(selectedFilters)
        }

        setSearchType(st || 'restaurants')
        setZoomLevel(z ? parseInt(z) : 13)
        if (st && q && c && d) {
          setOnload(false)
          setQuery(q)
          setSearchInput(q)
          setDistance(d)
        }
        if (sc && parseInt(sc) != 0) {
          setSelectedCard(parseInt(sc))
        }
      }
    }
  }, [])

  return (
    <>
      <CustomHead
        data={{
          description:
            'Find your next favorite restaurant in seconds.  Filter millions of foods to match your dietary restrictions and preferences, from low glycemic to family friendly.',
        }}
      />

      <div className={'w-full px-2'} ref={containerRef}>
        {isMobile && <MobileHeader handleMobileView={handleMobileView} />}
        <MapView
          loadingResults={loadingResults}
          mobileView={mobileView}
          searchResults={results}
          setDistance={setDistance}
          distance={distance}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          selectedCard={selectedCard}
          setSelectedCard={(val) => {
            setSelectedCard(val)
          }}
          handleMobileView={handleMobileView}
        />
      </div>
    </>
  )
}
