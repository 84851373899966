import { useFilterStore } from '@/hooks/useFilterStore'

type Props = {
  shouldSetSearch: boolean
}

const IndieChainToggle = (props: Props) => {
  const { shouldSetSearch } = props

  const filters = useFilterStore((state) => state.filters)
  const addFilter = useFilterStore((state) => state.addFilter)
  const removeFilter = useFilterStore((state) => state.removeFilter)
  const setSearchState = useFilterStore((state) => state.setSearchState)
  const resetSelectedCard = useFilterStore((state) => state.resetSelectedCard)

  const handleIndieClick = () => {
    const includesIndie = filters.includes('indie')
    const includesFranchise = filters.includes('franchise')
    if (includesFranchise) {
      if (shouldSetSearch) {
        setSearchState(true)
        resetSelectedCard()
      }
      if (includesIndie) {
        removeFilter('indie')
        return
      }
      addFilter('indie')
    }
    return
  }

  const handleFranchiseClick = () => {
    const includesIndie = filters.includes('indie')
    const includesFranchise = filters.includes('franchise')
    if (includesIndie) {
      if (shouldSetSearch) {
        setSearchState(true)
        resetSelectedCard()
      }
      if (includesFranchise) {
        removeFilter('franchise')
        return
      }
      addFilter('franchise')
    }
    return
  }

  const getToggleClasses = (type: string) => {
    if (filters.includes(type)) {
      return 'bg-black text-white dark:bg-white dark:text-black'
    }
    return 'bg-white text-black dark:bg-black dark:text-white'
  }

  return (
    <div className={'rounded-md gap-1 flex grid-flow-col mr-2'}>
      <div
        id="indie"
        className={`
              ${getToggleClasses('indie')}
               border-[1px] border-black dark:border-white items-center justify-center h-auto w-16 mb-1 relative inline-flex rounded-l-full focus:outline-none cursor-pointer`}
        onClick={handleIndieClick}
      >
        Indie
      </div>

      <div
        id="franchise"
        className={`
              ${getToggleClasses('franchise')}
               border-[1px] border-black dark:border-white items-center justify-center h-auto w-16 mb-1 relative inline-flex rounded-r-full focus:outline-none cursor-pointer`}
        onClick={handleFranchiseClick}
      >
        Chain
      </div>
    </div>
  )
}

export default IndieChainToggle
